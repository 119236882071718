<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <div class="row">
        <q-card
          square
          class="bg-white"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $t('rental.uk_international') }}
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <div class="row q-col-gutter-sm">
              <div class="col-12">
                <m-location-search
                  ref="pickUp"
                  :query="autocomplete"
                  :value="parameters.pickup.location"
                  :branch-only="!hasPermission('vehiclehire.deliveryandcollection')"
                  :no-saved="!hasPermission('vehiclehire.deliveryandcollection')"
                  :label="$t('pick_up_location')"
                  no-shadow
                  :placeholder="$t('input.location_airport_address')"
                  @selected="pickupSelect"
                />
              </div>
              <div class="col-12">
                <q-toggle
                  v-model="parameters.dropoff.visible"
                  :label="$t('drop_off_different_location')"
                />
              </div>
              <div
                v-if="parameters.dropoff.visible"
                class="col-12"
              >
                <m-location-search
                  ref="pickUp"
                  :query="autocomplete"
                  :value="parameters.dropoff.location"
                  :label="$t('drop_off_location')"
                  branch-only
                  no-saved
                  no-shadow
                  :placeholder="$t('input.location_airport_address')"
                  @selected="dropoffSelect"
                />
              </div>
              <div class="col-6">
                <date-field
                  v-model="parameters.pickup.date"
                  :min="minPickup"
                  :label="$t('date.pick_up')"
                />
              </div>
              <div class="col-6">
                <time-field
                  v-model="parameters.pickup.date"
                  :min="minPickup"
                  :minute-step="15"
                  :label="$t('time.pick_up')"
                />
              </div>
              <div class="col-6">
                <date-field
                  v-model="parameters.dropoff.date"
                  :min="minDropoff"
                  :label="$t('date.drop_off')"
                />
              </div>
              <div class="col-6">
                <time-field
                  v-model="parameters.dropoff.date"
                  :min="minDropoff"
                  :minute-step="15"
                  :label="$t('time.drop_off')"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta row">
        <q-btn
          :disabled="disabled"
          size="lg"
          color="primary"
          class="full-width"
          @click="submit"
        >
          {{ $tc('find.rental_car', 2) }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { locationLookup } from 'api/rental'
import date from 'utils/date-time'
import model from './model'
const { addToDate, subtractFromDate } = date
import { MLocationSearch, MUnifiedUsers, dateField, timeField } from 'components/'

export default {
  name: 'OndemandCarHire',
  components: {
    timeField,
    dateField,
    MLocationSearch,
    MUnifiedUsers
  },
  mixins: [authentication],
  data () {
    return {
      minRentalHours: 1,
      parameters: model(this.$route)
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    disabled () {
      const formFilled = this.parameters.dropoff.visible
        ? (this.parameters.pickup.location && this.parameters.dropoff.location)
        : this.parameters.pickup.location

      return !(formFilled && this.parameters.traveller && this.parameters.requester)
    },
    minPickup () {
      return date.roundMinutesUp(addToDate(date.newDate(), { hours: 4 }), 15)
    },
    minDropoff () {
      return date.roundMinutesUp(addToDate(this.parameters.pickup.date, { days: 1 }), 15)
    }
  },
  watch: {
    'parameters.pickup.location' (val) {
      if (!this.parameters.dropoff.visible) {
        this.parameters.dropoff.location = val
      }
    },
    'parameters.pickup.date' (val) {
      // Minus additional 15 mins to compensate for the rounding
      const dateToCompare = date.roundMinutesDown(subtractFromDate(this.parameters.dropoff.date, { hours: this.minRentalHours }), 15)
      if (date.newDate(val) >= dateToCompare) {
        this.parameters.dropoff.date = this.minDropoff
      }

      if (date.newDate(val) < this.minPickup) {
        this.parameters.pickup.date = this.minPickup
      }
    },
    'parameters.dropoff.date' (val) {
      if (date.newDate(val) < this.minDropoff) {
        this.parameters.dropoff.date = this.minDropoff
      }
    },
    'parameters.dropoff.visible': function (value, old) {
      if (!value) {
        this.parameters.dropoff.location = { ...this.parameters.pickup.location }
      } else {
        this.parameters.dropoff.location = null
      }
    },
    '$route.query.pickup': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.pickup.location = val
        }
      }
    },
    '$route.query.dropoff': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.dropoff.location = val
        }
      }
    },
    '$route.params.origin': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.pickup.location = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-car-hire-results' })
    },
    async autocomplete (address) {
      // Capitalizing the search query because it seems to sometimes return better results
      const locations = await locationLookup(address.charAt(0).toUpperCase() + address.slice(1))
      const data = locations.map(location => {
        return {
          ...location,
          description: location.label,
          place_id: location.value
        }
      })
      return data
    },
    pickupSelect (value) {
      this.parameters.pickup.location = value
    },
    dropoffSelect (value) {
      this.parameters.dropoff.location = value
    }
  }
}

</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/backgrounds/carhire.png')
  background-position 0 35%
  background-size cover
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.toggle
  display flex
  justify-content space-between
  padding 10px 100px
  padding-bottom 0
</style>
