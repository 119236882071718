<template>
  <q-page class="" padding>
    <on-demand-form
      ref="form"
      :location-query="locationSearch"
      :user="user"
      :initial="initial"
      :single="isSingle"
      :labels="labels"
      only-return
      time
      rental
      type="fas fa-car"
      @submit="onSubmit"
    >
      <template v-slot:return="slotProps">
        <q-toggle
          v-model="slotProps.formReturn"
          color="primary"
          :label="$t('drop_off_different_location')"
          @input="changeReturning"
        />
      </template>
    </on-demand-form>
  </q-page>
</template>

<script>
// TODO add script to container with slot for template!
import date from 'utils/date-time'
const { addToDate, newDate } = date
import OnDemandForm from '../form/'
import { placeMapper } from '../form/mappers'
import { locationLookup as locationSearch } from 'api/rental'
import { mapGetters } from 'vuex'
export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        location: {
          origin: this.$t('pick_up_location'),
          destination: this.$t('drop_off_location')
        },
        outbound: {
          date: this.$t('date.pick_up'),
          time: this.$t('time.pick_up')
        },
        inbound: {
          date: this.$t('date.drop_off'),
          time: this.$t('time.drop_off')
        }
      },
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: addToDate(newDate(), { minutes: 5 }),
          inbound: addToDate(newDate(), { minutes: 5, day: 1 })
        }
      },
      returning: true,
      locationResults: null,
      activeInput: '',
      isSingle: true
    }
  },
  computed: {
    ...mapGetters({ user: 'userLookup' }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = val
        }
      }
    }
  },
  methods: {
    changeReturning (v) {
      this.$refs.form.returning = v
      this.isSingle = !v
    },
    locationSearch,
    placeMapper,
    toggleReturn (value) {
      this.returning = value
    },
    onSubmit (values) {
      const mapped = {
        ...values,
        pickup: {
          location: {
            value: values.origin.value
          },
          date: values.date.outbound
        },
        dropoff: {
          location: {
            value: values.destination.value
          },
          date: values.date.inbound
        },
        depart: values.date.outbound,
        return: values.date.inbound
      }

      this.$store.dispatch('ondemand/stash', { parameters: mapped })
      this.$router.push({ name: 'ondemand-car-hire-results' })
    }
  }
}
</script>
