<template>
  <div class="vehicleCard">
    <div class="row q-pa-sm q-mb-sm">
      <div class="col-4 text-center column justify-around">
        <div>
          <img :src="item.vehicle.picture || require('assets/blank-vehicle-hire.png')" width="90%" height="auto">
        </div>
      </div>
      <div class="col-8 column justify-start q-pt-sm">
        <div class="absolute-right q-mt-lg q-mr-lg">
          <img v-if="vendor" :src="require(`assets/${vendor}.png`)" width="100px">
        </div>
        <div>
          <div class="text-subtitle1 text-weight-medium">
            {{ $t('rental.or_similar', { type: item.vehicle.description }) }}
          </div>
          <div class="text-faded text-body2">
            {{ carDetails.name }}
          </div>
        </div>
        <div v-if="carDetails.meta">
          <div class="q-pt-md">
            <q-chip icon="mdi-account-multiple" square class="carMetaChip">
              {{ carDetails.meta.seats }}
            </q-chip>
            <q-chip icon="mdi-car-door" square class="carMetaChip">
              {{ carDetails.meta.doors }}
            </q-chip>
            <q-chip icon="mdi-briefcase" square class="carMetaChip">
              {{ carDetails.meta.luggage }}
            </q-chip>
            <q-chip :icon="transmissionIcon(carDetails.meta.transmission)" square class="carMetaChip">
              {{ carDetails.meta.transmission }}
            </q-chip>
          </div>
        </div>
      </div>
    </div>
    <q-separator class="hr" />
    <div class="row q-mt-md">
      <div class="col-12 q-mb-md">
        <div class="text-subtitle1 text-weight-medium">
          {{ item.pickup.date | date }}
          <span class="text-faded text-weight-regular text-subtitle2">
            {{ addressInstructionType.pickup }}
          </span>
        </div>
        <div class="text-body2 text-grey-8">
          {{ item.start_location.text }}
        </div>
      </div>
      <div class="col-12">
        <div class="text-subtitle1 text-weight-medium">
          {{ item.return.date | date }}
          <span class="text-faded text-weight-regular text-subtitle2">
            {{ addressInstructionType.return }}
          </span>
        </div>
        <div class="text-body2 text-grey-8">
          {{ item.end_location.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRentalGroup } from '../rentalGroups'
import date from 'utils/date-time'
export default {
  filters: {
    time: value => date.toCivilTime(value),
    date: value => date.toMediumDateTime(value)
  },
  props: [ 'item', 'vendor' ],
  computed: {
    sameDropoff () {
      return this.item.start_location.text === this.item.end_location.text
    },
    carDetails () {
      return getRentalGroup(this.item.vehicle.group)
    },
    addressInstructionType () {
      if (this.item.start_location.address_type === 'deliveryCollection') {
        return {
          pickup: this.$t('deliver_to'),
          return: this.$t('collect_from')
        }
      }
      return {
        pickup: this.$t('pick_up_from'),
        return: this.$t('drop_off_at')
      }
    }
  },
  methods: {
    transmissionIcon (val) {
      if (val.toLowerCase().includes('automatic')) {
        return 'mdi-alpha-a-box'
      }
      return 'mdi-alpha-m-box-outline'
    }
  }
}
</script>
<style lang="stylus" scoped>
.vehicleCard
  min-height 160px
.carMetaChip
  background-color $grey-2
  margin-left: 0px;
  margin-right 8px;
</style>
