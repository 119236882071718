<template>
  <q-page>
    <div class="layout-padding">
      <div class="row results q-col-gutter-sm">
        <results-card
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :index="index"
          :vendor="vendor"
          :rental-days="daysOnHire"
          :selected="selected === index"
          @selected="setSelected(item, index)"
        />
        <m-empty-state
          v-if="items && items.length === 0"
          icon="directions_car"
          style="margin:auto"
        >
          {{ $t('rental.no_cars_found') }}
        </m-empty-state>
      </div>
    </div>
    <q-footer v-if="$q.platform.is.mobile" class="bg-white shadow-5 q-pa-sm q-py-md row justify-around">
      <q-btn
        :label="stash.selected ? $t('continue') : $t('select_an_option_to_continue')"
        color="primary"
        size="lg"
        class="full-width"
        :disable="!stash.selected"
        @click="$router.push({name: 'ondemand-car-hire-summary', params: {token: stash.selected.value.token}})"
      />
    </q-footer>
  </q-page>
</template>

<script>
import resultsCard from './results-card'
import store from 'store'
import loading from 'utils/loading'
import date from 'utils/date-time'
import { search } from 'api/rental'
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
import i18n from 'i18n'

export default {
  name: 'OndemandCarHireResults',
  components: {
    resultsCard
  },
  data () {
    return {
      items: null,
      selected: null,
      vendor: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    daysOnHire () {
      let diff = date.getDateDiff(this.stash.parameters.dropoff.date, this.stash.parameters.pickup.date, 'days').values.days
      // Hire will always be for at least 1 day
      return diff >= 1 ? `${Math.ceil(diff)} days` : `1 day`
    }
  },
  mounted () {
    if (this.stash.selected) {
      this.selected = this.stash.selected.index
    }
    this.vendor = process.env.VUE_APP_RENTAL_VENDOR
  },
  methods: {
    setSelected (item, index) {
      this.selected = index
      this.$store.dispatch('ondemand/stash', {
        selected: {
          value: {
            ...item,
            index: index
          }
        }
      })
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { parameters: { pickup, dropoff, traveller, requester } } = store.getters['ondemand/stash']
    const origin = pickup.location.place_id || pickup.location.value
    const destination = dropoff.location?.place_id || dropoff.location?.value

    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.rental'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const params = {
        origin,
        destination: destination || origin,
        pickup: date.toCivilDateTime(pickup.date),
        return: date.toCivilDateTime(dropoff.date),
        user: traveller.value,
        requester: requester.value
      }

      const { data } = await search(params)
      next(vm => {
        vm.items = data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.results
  max-width 768px
  margin 0
.resultCard
  margin 0px 0px 15px 0px
  @media (max-width 768px)
    margin-bottom 1px
hr
  width 100%
  margin-bottom 16px
.q-card
  transition: all .3s ease;
h4
  margin 35px 0 15px
</style>
