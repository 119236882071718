const groups = {
  '1': {
    doors: '3-5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '2': {
    doors: '3-5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '3': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '3A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '3D': {
    doors: '3-5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual [Diesel]'
  },
  '4': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '4A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '5': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '5A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '5D': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual [Diesel]'
  },
  '6': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '7': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '8': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '9A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '10A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '12A': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Automatic'
  },
  '13': {
    doors: '5',
    seats: '4',
    luggage: 4,
    transmission: 'Manual'
  },
  '14': {
    doors: '5',
    seats: '7',
    luggage: 8,
    transmission: 'Manual'
  },
  '14A': {
    doors: '5',
    seats: '7',
    luggage: 8,
    transmission: 'Automatic'
  },
  '15': {
    doors: '5',
    seats: '9',
    luggage: '8',
    transmission: 'Manual'
  },
  '15A': {
    doors: '5',
    seats: '9',
    luggage: '8',
    transmission: 'Automatic'
  },
  'V1': {
    doors: '3',
    seats: '3',
    luggage: 'Small - Medium Cargo',
    transmission: 'Manual'
  },
  'V2': {
    doors: '3',
    seats: '3',
    luggage: 'Small - Medium Cargo',
    transmission: 'Manual'
  },
  'V3': {
    doors: '3',
    seats: '3',
    luggage: 'Medium Cargo',
    transmission: 'Manual'
  },
  'V4': {
    doors: '3',
    seats: '3',
    luggage: 'Medium - Large Cargo (Tall)',
    transmission: 'Manual'
  },
  'V7': {
    doors: '3',
    seats: '3',
    luggage: 'Large Cargo',
    transmission: 'Manual'
  }
}

function createDefault (id = null) {
  var response = {
    doors: '3-5',
    seats: '2-4',
    luggage: '4-6',
    transmission: 'Manual'
  }

  if (id) {
    const lastChar = id.toLowerCase().substr(id.length - 1)
    const familyCode = id.toLowerCase().substr(0, id.length - 1)

    if (groups[familyCode]) response = groups[familyCode]

    if (lastChar === 'a') {
      response.transmission = 'Automatic'
    }
  }

  return response
}

export function getRentalGroup (groupId = null) {
  var response = {
    name: `Mobilleo Group ${groupId}`,
    meta: null
  }
  if (groupId && groups[groupId]) {
    response.meta = groups[groupId]
  } else {
    response.meta = createDefault(groupId)
  }
  return response
}
