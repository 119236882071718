<template>
  <q-card class="cursor-pointer non-selectable resultCard" :class="cardClass" @click.native="$emit('selected')">
    <div class="car-img">
      <img :src="item.vehicle.picture || require('assets/blank-vehicle-hire.png')" width="75%" height="auto">
    </div>

    <div class="car-description">
      <h6 class="text-capitalize">
        {{ item.vehicle.description }}
      </h6>
    </div>

    <div class="car-name">
      <h6 class="text-body2 text-faded text-capitalize text-weight-regular">
        {{ carDetails.name }}
      </h6>
    </div>
    <div class="car-total">
      <div class="text-h5 text-primary">
        {{ item.price.text }}
      </div>
    </div>
    <div v-if="carDetails.meta && !$q.platform.is.mobile" class="car-icons q-pt-sm">
      <q-chip icon="mdi-account-multiple" square class="carMetaChip">
        {{ carDetails.meta.seats }}
      </q-chip>
      <q-chip icon="mdi-car-door" square class="carMetaChip">
        {{ carDetails.meta.doors }}
      </q-chip>
      <q-chip icon="mdi-briefcase" square class="carMetaChip">
        {{ carDetails.meta.luggage }}
      </q-chip>
      <q-chip :icon="transmissionIcon(carDetails.meta.transmission)" square class="carMetaChip">
        {{ carDetails.meta.transmission }}
      </q-chip>
    </div>
  </q-card>
</template>
<script>
import { getRentalGroup } from '../rentalGroups'
import date from 'utils/date-time'
export default {
  filters: {
    time: value => date.toCivilTime(value)
  },
  props: [ 'item', 'selected', 'vendor', 'rentalDays' ],
  computed: {
    cardClass () {
      if (this.selected) return [ 'bg-primary-tint', 'shadow-5', 'selected' ]
      return null
    },
    sameDropoff () {
      return this.location.pickup.location.code === this.location.dropoff.location.code
    },
    carDetails () {
      return getRentalGroup(this.item.vehicle.group)
    }
  },
  methods: {
    transmissionIcon (val) {
      if (val.toLowerCase().includes('automatic')) {
        return 'mdi-alpha-a-box'
      }
      return 'mdi-alpha-m-box'
    }
  }
}
</script>
<style lang="stylus" scoped>
.resultCard
  min-height 160px
  width 100vw
  max-width 600px
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-template-areas "car-description car-name" \ "car-img car-total" \ "car-img ." \ "car-icons car-icons"
  justify-items start
  align-items start
  padding 8px
  @media (max-width 768px)
    margin 0
    border-radius 0
    max-width 100vw

.car-description
  grid-area car-description

.car-img
  grid-area car-img

.car-name
  grid-area car-name
  justify-self end

.car-total
  grid-area car-total
  justify-self end
  align-self end

.total-text
  grid-area total-text
  justify-self end

.car-icons
  grid-area car-icons
  width 100%
  display flex
  flex-direction row
  justify-content space-between

.car-vendor
  grid-area car-vendor

.selected
  outline 0.01px solid var(--q-color-primary)
  & .text-h4
    color var(--q-color-primary)
  & .carMetaChip
    background-color: var(--q-color-primary);
    color white
.carMetaChip
  background-color $grey-2
  margin-left 0px
.details
  background-color #fcfcfc
</style>
